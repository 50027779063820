<template>
  <!-- 往来款账户 -->
  <div
    class="bg-white px-3 mt-5"
    style="margin: -20px; margin-top: 1rem; margin-bottom: 0 !important"
  >
    <el-form :model="formInline" class="demo-form-inline">
      <el-form-item label="付款账户：">
        <!--banksclass 1-表示收付款账户  2表示往来款账户 9999表示项目全部账户-->
        <sfbanks-select
          @select-change="fkbanksselect"
          placeholder="请选择付款账户"
          banksclass="9999"
        ></sfbanks-select>
      </el-form-item>
      <el-form-item label="付款时间：">
        <el-date-picker
          v-model="formInline.billing_date"
          type="date"
          placeholder=""
          size="small"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="摘要：">
        <el-input
          v-model="formInline.textarea"
          :rows="2"
          type="textarea"
          placeholder="Please input"
        />
      </el-form-item>
      <el-form-item label="转款金额：">
        <el-input
          v-model="formInline.amount_money"
          :rows="2"
          placeholder="Please input"
        />
      </el-form-item>
      <el-form-item label="收款账户：">
        <!--banksclass 1-表示收付款账户  2表示往来款账户 9999表示项目全部账户-->
        <sfbanks-select
          @select-change="skbanksselect"
          placeholder="请选择收款账户"
          banksclass="9999"
        ></sfbanks-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from "axios";
import { ElMessageBox } from "element-plus";
export default {
  data() {
    return {
      formInline: {
        billing_date: "", //转款时间
        textarea: "", //摘要

        skbanksid: "", //收款账户ID
        skbanksno: "", //收款账号
        skbanksname: "", //收款账户银行名
        skaccount_name:"",//收款账户名

        fkbanksid: "", //付款账户
        fkbanksno: "", //付款账号
        fkbanksname: "", //付款账户银行名
        fkaccount_name:"",//付款账户名

        amount_money: 0, //转款金额
        project_id: window.sessionStorage.getItem("project_ids"),
      },
    };
  },
  methods: {
    //选择付款银行
    fkbanksselect(value) {
      this.formInline.fkbanksid = value.id;
      this.formInline.fkbanksno = value.bank_no;
      this.formInline.fkbanksname = value.bank_name;
      this.formInline.fkaccount_name=value.account_name
    },
    //选择收款银行
    skbanksselect(value) {
      this.formInline.skbanksid = value.id;
      this.formInline.skbanksno = value.bank_no;
      this.formInline.skbanksname = value.bank_name;
      this.formInline.skaccount_name=value.account_name
    },

    //提交表单
    onSubmit() {
      //如果转款账户为同一个账户(或有一个为空)则不能操作
      if (
        this.formInline.fkbanksid == this.formInline.skbanksid ||
        this.formInline.skbanksid == "" ||
        this.formInline.fkbanksid == ""
      ) {
        ElMessageBox.alert("不能为同一账户 或 账户不能为空", "提示", {
          confirmButtonText: "OK",
        });
      } else {
        this.addbanksdetaied();
      }
    },
    //账户间转账登记
    addbanksdetaied() {
      axios
        .post("admin/SfBanksDetaied", this.formInline, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        // .then((response) => {
        .then(() => {
          ElMessageBox.alert("新增成功！", "提示", {
            confirmButtonText: "OK",
          });
          
        })
        //.catch((error) => {
        .catch(() => {
          ElMessageBox.alert("失败！", "提示", {
            confirmButtonText: "OK",
          });
        })
        .then(() => {
          // 总是会执行
        });
    },
  },
};
</script>